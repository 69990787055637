export const environment = 
{
  production: window["env"]["production"] || false,
  APP_NAME: window["env"]["APP_NAME"] || 'SaasTrilloApps',
  Title: window["env"]["Title"] || 'Saas Trillo Apps',
  BaseURL: window["env"]["BaseURL"] || 'https://api.eng-dev-2-402218.trilloapps.com',
  AccessToken : window["env"]["AccessToken"] || 'lsSaasTrilloAppsAccessToken',
  ImagesURL : window["env"]["ImagesURL"] || '',
  Theme : window["env"]["Theme"] || 'trillo',
  OrgName : 'cloud',
};